import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { TradingOrderStatus, ValstroEntitlements } from '@oms/generated/frontend';
import type { VisibleTradingOrderInfoFragment as TOFragment } from '@oms/generated/frontend';
import { openTradingOrderModify } from '@app/common/types/orders/trading-order/open.modify.trading-order';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';

function isVisible(data: TOFragment[]) {
  return data.length === 1 && !!data[0] && data[0].status === TradingOrderStatus.Active;
}

export const modifyTradingOrderOnChange = (
  ctx: ActionContext<TOFragment, ActionComponentConfig<TOFragment>>
) => {
  const { lifecycle, data, workspace } = ctx;
  const selectedRow = data[0];
  const id = selectedRow?.id;
  const symbol = selectedRow?.instrument?.mappings?.displayCode;

  ctx.notify({ isDisabled: !isVisible(data) });

  if (lifecycle === 'change' && id) {
    openTradingOrderModify(workspace, id, symbol);
  }
};

export const modifyTradingOrderAction: ActionDefFactory<TOFragment> = (builder) =>
  builder
    .name('modify_trading_order')
    .toolbar((t) =>
      t
        .component('action-button')
        .id('modify_trading_order_button')
        .location('HorizontalToolbarRight')
        .props({
          isDisabled: true,
          content: 'Modify'
        })
    )
    .access(({ appContainer }) => {
      const authService = appContainer.resolve(AuthService);

      return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
    })
    .customMenu((m) => m.name('Modify').visible(({ rowData }) => isVisible(rowData)))
    .onChange<ActionComponentConfig<TOFragment>>(modifyTradingOrderOnChange);
