import { createAppSubSchemaRegistry, APP_SUB_SCHEMA, migrateSubSchema } from '@oms/frontend-schemas';
import type { CollectionWithSchemaPayload, CollectionMigrationStrategy } from '@oms/frontend-schemas';
import { VERSIONED_SCHEMAS as routeOrderFormVersionedSchemas } from '@app/widgets/trading/route-order/schemas/form-values';
import { VERSIONED_SCHEMAS as routeOrderVersionedSchemas } from '@app/widgets/trading/route-order/schemas/form-input';
import { VERSIONED_SCHEMAS as bumpPriceVersionedSchemas } from '@app/widgets/trading/montage/commands/bump-price/schema';
import { VERSIONED_SCHEMAS as createUnboundTradingOrderVersionedSchemas } from '@app/widgets/trading/route-order/commands/create-unbound-trading-order/schema';
import { VERSIONED_SCHEMAS as snapshotVersionedSchemas } from '@app/app-config/schemas/snapshots';
import { VERSIONED_SCHEMAS as flexLayoutVersionedSchemas } from '@app/app-config/schemas/flex-layout';
import { VERSIONED_SCHEMAS as montageLayoutVersionedSchemas } from '@app/widgets/trading/montage/schemas/layout';
import { VERSIONED_SCHEMAS as remoteFormVersionedSchemas } from '@app/widgets/system/remote-form/schema';
import { SnapshotDocType } from '@app/data-access/offline/collections/snapshots/snapshots.collection';
import { migrateSnapshotSubSchema } from './sub-schema.snapshots';

export const APP_SUB_SCHEMA_REGISTRY = createAppSubSchemaRegistry({
  [APP_SUB_SCHEMA.BUMP_PRICE]: {
    versionedSchemas: bumpPriceVersionedSchemas
  },
  [APP_SUB_SCHEMA.CREATE_UNBOUND_TO]: {
    versionedSchemas: createUnboundTradingOrderVersionedSchemas
  },
  [APP_SUB_SCHEMA.SNAPSHOT]: {
    versionedSchemas: snapshotVersionedSchemas
  },
  [APP_SUB_SCHEMA.FLEX_LAYOUT]: {
    versionedSchemas: flexLayoutVersionedSchemas
  },
  // Snapshot-based sub-schemas
  [APP_SUB_SCHEMA.ROUTE_ORDER_FORM_INPUT]: {
    versionedSchemas: routeOrderVersionedSchemas
  },
  [APP_SUB_SCHEMA.ROUTE_ORDER_FORM_VALUES]: {
    versionedSchemas: routeOrderFormVersionedSchemas
  },
  [APP_SUB_SCHEMA.MONTAGE]: {
    versionedSchemas: montageLayoutVersionedSchemas
  },
  [APP_SUB_SCHEMA.SYSTEM_REMOTE_FORM]: {
    versionedSchemas: remoteFormVersionedSchemas
  }
});

export function migrateSubSchemaFromRegistry<
  S extends CollectionMigrationStrategy,
  T extends CollectionWithSchemaPayload<any>
>(strategy: S, doc: T) {
  switch (true) {
    case strategy === 'payload-based' && 'payload' in doc:
      return migrateSubSchema(doc, APP_SUB_SCHEMA_REGISTRY);
    case strategy === 'snapshot' && 'payload' in doc:
      // TODO: improve this type
      return migrateSnapshotSubSchema(doc as unknown as SnapshotDocType, APP_SUB_SCHEMA_REGISTRY);
    default: {
      throw new Error('Invalid strategy or document type');
    }
  }
}
