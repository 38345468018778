import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { openRejectInvestorOrder } from '@app/generated/sdk';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { InvestorOrderStatus, ValstroEntitlements } from '@oms/generated/frontend';
import type { VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment } from '@oms/generated/frontend';
import { PROCESS_ID } from '@valstro/workspace';

function isVisible(rowData: IOFragment[] | undefined) {
  const isOnlyOneRowSelected = !!rowData && rowData.length === 1;
  const selectedRow = !!rowData && rowData[0];
  return (
    isOnlyOneRowSelected &&
    selectedRow &&
    'status' in selectedRow &&
    selectedRow?.status === InvestorOrderStatus.Unaccepted &&
    selectedRow?.__typename === 'VisibleInvestorOrder'
  );
}

export const rejectInvestorOrderOnChange = async (
  ctx: ActionContext<IOFragment, ActionComponentConfig<IOFragment>>
) => {
  const { lifecycle, data } = ctx;

  const selectedRow = data[0];

  const isUnacceptedIO = isVisible(data);

  ctx.notify({ isDisabled: !isUnacceptedIO });

  if (lifecycle === 'change') {
    if (!selectedRow) {
      return;
    }
    const id = selectedRow.id;
    if (!id) {
      return;
    }

    await openRejectInvestorOrder(PROCESS_ID.LEADER, {
      form: {
        formId: `reject-investor-order-${id}`,
        input: {
          id
        }
      }
    });
  }
};

export const rejectInvestorOrderAction: ActionDefFactory<IOFragment> = (builder) =>
  builder
    .name('reject_order')
    .toolbar((t) =>
      t.component('action-button').id('reject_order_button').location('HorizontalToolbarRight').props({
        isDisabled: true,
        content: 'Reject'
      })
    )
    .access(({ appContainer }) => {
      const authService = appContainer.resolve(AuthService);

      return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
    })
    .customMenu((m) => m.name('Reject').visible(({ rowData }) => isVisible(rowData)))
    .onChange<ActionComponentConfig<IOFragment>>(rejectInvestorOrderOnChange);
