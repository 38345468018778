import type { CSSProperties } from 'react';
import { createPrefixedGetterFn } from '@oms/shared/util';
import type { Prefixed } from '@oms/shared/util-types';
import { t } from '@oms/codegen/translations';
import { FormContract, FieldDefinition } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import { QuantityType } from '@oms/generated/frontend';
import type {
  LeftClickSettingsType,
  MontageSettingsSchema
} from '@app/common/zod/user-preferences.zod.schemas';
import { createFormContractTemplateOptions } from '../../user-preferences.styling.util';
import {
  createDestinationIdField,
  createDisplaySizeField,
  createGtdTimestampField,
  createInitiateOrderField,
  createOrderSizeField,
  createPriceTypeField,
  createQuantityTypeField,
  createQuantityValueField,
  createSideTypeField,
  createStrategyField,
  createStrategyPresetsField,
  createTifDurationField,
  createTimeInForceTypeField,
  createTimeInForceValueField,
  displayQuotesInShares,
  hideOddLots,
  sendAttributable,
  typeField
} from './montage-settings.form-fields';

const conditionalValueFieldStyle: CSSProperties = {
  marginLeft: '4px',
  width: 'calc(72% - 4px)'
};

type BaseLeftClickFieldDefinitions = {
  destinationId: ReturnType<typeof createDestinationIdField>;
  displaySize: ReturnType<typeof createDisplaySizeField>;
  gtdTimestamp: ReturnType<typeof createGtdTimestampField>;
  initiateOrder: ReturnType<typeof createInitiateOrderField>;
  orderSize: ReturnType<typeof createOrderSizeField>;
  priceType: ReturnType<typeof createPriceTypeField>;
  quantityType: ReturnType<typeof createQuantityTypeField>;
  quantityValue: ReturnType<typeof createQuantityValueField>;
  sideType: ReturnType<typeof createSideTypeField>;
  strategy: ReturnType<typeof createStrategyField>;
  strategyPresets: ReturnType<typeof createStrategyPresetsField>;
  tifDuration: ReturnType<typeof createTifDurationField>;
  timeInForceType: ReturnType<typeof createTimeInForceTypeField>;
  timeInForceValue: ReturnType<typeof createTimeInForceValueField>;
};

type LeftClickFieldDefinitions<Type extends LeftClickSettingsType | undefined = undefined> =
  Type extends undefined
    ? BaseLeftClickFieldDefinitions
    : Type extends 'ask'
      ? Prefixed<BaseLeftClickFieldDefinitions, Extract<Type, 'ask'>>
      : Prefixed<BaseLeftClickFieldDefinitions, Extract<Type, 'bid'>>;

const askFields: LeftClickFieldDefinitions<'ask'> = {
  askDestinationId: createDestinationIdField('ask'),
  askDisplaySize: createDisplaySizeField('ask'),
  askGtdTimestamp: createGtdTimestampField('ask'),
  askInitiateOrder: createInitiateOrderField('ask'),
  askOrderSize: createOrderSizeField('ask'),
  askPriceType: createPriceTypeField('ask'),
  askQuantityType: createQuantityTypeField('ask'),
  askQuantityValue: createQuantityValueField('ask'),
  askSideType: createSideTypeField('ask'),
  askStrategy: createStrategyField('ask'),
  askStrategyPresets: createStrategyPresetsField('ask'),
  askTifDuration: createTifDurationField('ask'),
  askTimeInForceType: createTimeInForceTypeField('ask'),
  askTimeInForceValue: createTimeInForceValueField('ask')
};

const bidFields: LeftClickFieldDefinitions<'bid'> = {
  bidDestinationId: createDestinationIdField('bid'),
  bidDisplaySize: createDisplaySizeField('bid'),
  bidGtdTimestamp: createGtdTimestampField('bid'),
  bidInitiateOrder: createInitiateOrderField('bid'),
  bidOrderSize: createOrderSizeField('bid'),
  bidPriceType: createPriceTypeField('bid'),
  bidQuantityType: createQuantityTypeField('bid'),
  bidQuantityValue: createQuantityValueField('bid'),
  bidSideType: createSideTypeField('bid'),
  bidStrategy: createStrategyField('bid'),
  bidStrategyPresets: createStrategyPresetsField('bid'),
  bidTifDuration: createTifDurationField('bid'),
  bidTimeInForceType: createTimeInForceTypeField('bid'),
  bidTimeInForceValue: createTimeInForceValueField('bid')
};

const createLeftClickFieldSchema = <Type extends LeftClickSettingsType>(
  type: Type,
  f: LeftClickFieldDefinitions<Type>
) => {
  const prefixedGetterFn = createPrefixedGetterFn<Type>(type);
  const data = f as unknown as Prefixed<BaseLeftClickFieldDefinitions, Type>;
  const getField = (key: keyof BaseLeftClickFieldDefinitions) => prefixedGetterFn(data, key);
  const quantityTypeField = getField('quantityType');
  return FieldDefinition.box('wrapper', [
    FieldDefinition.box('askSettings-section-wrapper', [
      getField('initiateOrder'),
      FieldDefinition.simpleGrid(`askSettings-main-fields`, 3, [
        getField('sideType'),
        FieldDefinition.box(
          'quantity-fields-wrapper',
          [
            FieldDefinition.box('quantity-type-wrapper', [quantityTypeField], {
              style: {
                flexGrow: 1
              }
            }),
            FieldDefinition.box('quantity-value-wrapper', [getField('quantityValue')], {
              condition: {
                when: `${quantityTypeField.name}.id`,
                is: QuantityType.ExplicitValue
              },
              style: conditionalValueFieldStyle
            }),
            FieldDefinition.box('order-size-wrapper', [getField('orderSize')], {
              condition: {
                when: `${quantityTypeField.name}.id`,
                is: QuantityType.OrderSizes
              },
              style: conditionalValueFieldStyle
            })
          ],
          {
            sx: {
              display: 'flex'
            }
          }
        ),
        getField('priceType'),
        getField('timeInForceType'),
        getField('displaySize'),
        getField('destinationId')
      ]),
      FieldDefinition.simpleGrid('strategy-fields', 2, [getField('strategy'), getField('strategyPresets')])
    ])
  ]);
};

export const montageSettingsContract = FormContract.create<MontageSettingsSchema>()
  .fields({
    type: typeField,
    // Left click settings
    ...askFields,
    ...bidFields,

    // Flags
    displayQuotesInShares,
    hideOddLots,
    sendAttributable
  })
  .schema((f) => ({
    fields: [
      FieldDefinition.box('wrapper', [
        FieldDefinition.box(
          'montage-settings-wrapper',
          [
            {
              name: 'left-click-settings-tabs',
              component: 'tabs',
              tabs: {
                defaultValue: 'bidSettings',
                orientation: 'horizontal',
                config: {
                  bidSettings: {
                    title: 'Bid settings',
                    fields: [createLeftClickFieldSchema('bid', f)]
                  },
                  askSettings: {
                    title: 'Ask settings',
                    fields: [createLeftClickFieldSchema('ask', f)]
                  }
                }
              }
            }
          ],
          {
            sx: {
              marginBottom: 'large'
            }
          }
        ),
        FieldDefinition.box('flags', [f.hideOddLots, f.displayQuotesInShares, f.sendAttributable], {
          sx: { display: 'flex', justifyContent: 'flex-start' }
        })
      ])
    ]
  }))
  .template(
    'simple',
    createFormContractTemplateOptions({
      submitLabel: t('app.common.save')
    })
  );

export type MontageSettingsContractType = typeof montageSettingsContract;

export type MontageSettingsFormValues = InferFormValuesFromFormContract<MontageSettingsContractType>;
