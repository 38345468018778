import { openRouteOrder as _openRouteOrder } from '@app/generated/sdk';
import { FORM_ENUM } from '@app/generated/common';
import {
  RouteOrderFormLocationType,
  ROUTE_ORDER_FORM_TYPE,
  ROUTE_ORDER_FORM_LOCATION_TYPE
} from './route-order.form-common';
import type { RouteOrderFormType } from './route-order.form-common';
import { getLeaderOrTabId } from '@app/common/workspace/workspace.util';
import { merge } from 'lodash';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import type { FoundationWorkspace } from '@oms/frontend-foundation';
import { TradingOrderCategory } from '@oms/generated/frontend';
import { defaultWindowOptions } from './route-order.form-builder.config';

type OpenRouteOrderHelperOptions = {
  workspace: AppWorkspace | FoundationWorkspace;
  type: RouteOrderFormType;
  location?: RouteOrderFormLocationType;
  overrides: Parameters<typeof _openRouteOrder>[1];
  parentActorOrId?: Parameters<typeof _openRouteOrder>[0];
};

function openRouteOrderHelper({
  workspace,
  type,
  location,
  overrides = {},
  parentActorOrId
}: OpenRouteOrderHelperOptions) {
  const container = workspace.meta.container;
  if (!container) {
    throw new Error('Workspace container not found');
  }
  const parentId = parentActorOrId || getLeaderOrTabId(container);
  const widgetTypeOverride = overrides.windowOptions?.meta?.widgetType;
  _openRouteOrder(parentId, {
    ...overrides,
    windowOptions: {
      ...overrides.windowOptions,
      meta: {
        ...overrides.windowOptions?.meta,
        widgetType: widgetTypeOverride
          ? widgetTypeOverride
          : `${FORM_ENUM.ROUTE_ORDER}_${type}${location ? `_${location}` : ''}`
      }
    }
  }).catch((e) => {
    openMessageDialog(`Error: ${e}`, workspace).catch(console.error);
    console.error(e);
  });
}

export function openRouteInvestorOrder(
  workspace: AppWorkspace | FoundationWorkspace,
  { id, symbol }: { id: string; symbol: string },
  overrides: Parameters<typeof _openRouteOrder>[1] = {},
  parentActorOrId?: Parameters<typeof _openRouteOrder>[0]
) {
  openRouteOrderHelper({
    workspace,
    type: ROUTE_ORDER_FORM_TYPE.ROUTE,
    overrides: merge(
      {
        title: `Route Order ${symbol}`,
        windowOptions: {
          ...defaultWindowOptions,
          height: 340,
          isPinnable: false
        },
        form: {
          formId: `route-order-${id}`,
          input: {
            mode: {
              type: 'route',
              investorOrderId: id
            }
          }
        }
      },
      overrides
    ),
    parentActorOrId
  });
}

export function openModifyTradingOrder(
  workspace: AppWorkspace | FoundationWorkspace,
  { id, symbol }: { id: string; symbol: string },
  overrides: Parameters<typeof _openRouteOrder>[1] = {},
  parentActorOrId?: Parameters<typeof _openRouteOrder>[0]
) {
  openRouteOrderHelper({
    workspace,
    type: ROUTE_ORDER_FORM_TYPE.MODIFY,
    overrides: merge(
      {
        title: `Modify Trading Order ${symbol}`,
        form: {
          formId: `modify-trading-order-${id}`,
          input: {
            mode: {
              type: 'modify',
              tradingOrderId: id
            }
          }
        }
      },
      overrides
    ),
    parentActorOrId
  });
}

export function openMontageModifyTradingOrder(
  workspace: AppWorkspace | FoundationWorkspace,
  { id }: { id: string },
  overrides: Parameters<typeof _openRouteOrder>[1] = {},
  parentActorOrId?: Parameters<typeof _openRouteOrder>[0]
) {
  openRouteOrderHelper({
    workspace,
    type: ROUTE_ORDER_FORM_TYPE.MODIFY,
    location: ROUTE_ORDER_FORM_LOCATION_TYPE.MONTAGE,
    overrides: merge(
      {
        title: 'Modify Trading Order',
        form: {
          formId: `modify-trading-order-${id}`,
          input: {
            mode: {
              type: 'modify',
              tradingOrderId: id,
              location: 'montage'
            }
          }
        }
      },
      overrides
    ),
    parentActorOrId
  });
}

export function openCreateTradingOrder(
  workspace: AppWorkspace | FoundationWorkspace,
  overrides: Parameters<typeof _openRouteOrder>[1] = {},
  parentActorOrId?: Parameters<typeof _openRouteOrder>[0]
) {
  openRouteOrderHelper({
    workspace,
    type: ROUTE_ORDER_FORM_TYPE.CREATE,
    overrides: merge(
      {
        title: 'Trading Order Entry',
        form: {
          formId: `trading-order-entry`,
          input: {}
        }
      },
      overrides
    ),
    parentActorOrId
  });
}

export function openMontageCreateTradingOrder(
  workspace: AppWorkspace | FoundationWorkspace,
  overrides: Parameters<typeof _openRouteOrder>[1] = {},
  parentActorOrId?: Parameters<typeof _openRouteOrder>[0]
) {
  openRouteOrderHelper({
    workspace,
    type: ROUTE_ORDER_FORM_TYPE.CREATE,
    location: ROUTE_ORDER_FORM_LOCATION_TYPE.MONTAGE,
    overrides: merge(
      {
        title: 'Trading Order Entry'
      },
      overrides
    ),
    parentActorOrId
  });
}

export function openUnboundMontageCreateTradingOrder(
  workspace: AppWorkspace | FoundationWorkspace,
  {
    id,
    instrumentId,
    instrumentDisplayCode
  }: { id: string; instrumentId: string; instrumentDisplayCode: string },
  overrides: Parameters<typeof _openRouteOrder>[1] = {},
  parentActorOrId?: Parameters<typeof _openRouteOrder>[0]
) {
  openRouteOrderHelper({
    workspace,
    type: ROUTE_ORDER_FORM_TYPE.CREATE,
    location: ROUTE_ORDER_FORM_LOCATION_TYPE.MONTAGE,
    overrides: merge(
      {
        title: `Unbound Trading Order: ${instrumentDisplayCode}`,
        form: {
          formId: `unbound-trading-order-${id}`,
          initialValues: {
            hiddenMode: {
              type: 'create',
              location: 'montage'
            },
            tradingOrderCategory: TradingOrderCategory.Montage,
            instrument: {
              id: instrumentId
            }
          },
          input: {
            mode: {
              type: 'create'
            }
          }
        }
      },
      overrides
    ),
    parentActorOrId
  });
}
