export const COLLECTION_NAME = {
  ACTIONS: 'actions',
  SNAPSHOTS: 'snapshots',
  COMPLETED_MIGRATIONS: 'completed_migrations',
  USER_PREFERENCES: 'user_preferences'
} as const;

export type CollectionName = (typeof COLLECTION_NAME)[keyof typeof COLLECTION_NAME];

export type CollectionItem = {
  collectionMigrationsFolderPath: string;
};

export type CollectionRegistery = Record<CollectionName, CollectionItem>;
