import type { Maybe } from '@oms/generated/frontend';

// Get parsed payload from sourceEventPayload.
// Recursively apply JSON.parse to sourceEventPayload until it is parsed and we get Object from string.
export function getParsedPayload<T>(sourceEventPayload: string | T): Maybe<T> {
  if (typeof sourceEventPayload === 'string') {
    try {
      return getParsedPayload<T>(JSON.parse(sourceEventPayload) as string);
    } catch (error) {
      console.error('Error parsing sourceEventPayload', error);
      return null;
    }
  } else {
    return sourceEventPayload;
  }
}
