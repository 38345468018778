import { useMemo } from 'react';
import { isEqual } from 'lodash';
import type { VisibleInvestorOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { SummaryGrid } from '@oms/frontend-foundation';
import type { SummaryGridHeading, SummaryGridSx } from '@oms/frontend-foundation';
import { orderSummaryData } from '@app/common/summaries/order-fields';
import { allOrderFields } from '../investor-order-view/investor-order-view.order-details.widget';

const headings: SummaryGridHeading[] = [{ label: 'Property' }, { label: 'Current' }, { label: 'New' }];

const summaryOfChangesSx: SummaryGridSx[] = [
  null,
  {
    heading: { color: 'text.primary' },
    row: { color: 'text.semiMinor' }
  }
];

export const InvestorOrderDetailsSummaryOfChanges = ({
  currentIO,
  newIO
}: {
  currentIO: Partial<VisibleInvestorOrderInfoWithAllocationsFragment>;
  newIO: Partial<VisibleInvestorOrderInfoWithAllocationsFragment>;
}) => {
  const data = useMemo(() => {
    const curFields = orderSummaryData('', allOrderFields, currentIO);
    const newFields = orderSummaryData('', allOrderFields, newIO);

    const [curValues] = curFields.value;
    const [newValues] = newFields.value;
    const changedFields = curFields.fields.filter(
      ({ fieldName }) => !isEqual(curValues[fieldName], newValues[fieldName])
    );

    return {
      ...curFields,
      fields: changedFields,
      value: [...curFields.value, ...newFields.value]
    };
  }, [currentIO, newIO]);

  return (
    <SummaryGrid
      title={'Summary of Changes'}
      fields={data.fields}
      data={data.value}
      headings={headings}
      sx={summaryOfChangesSx}
    />
  );
};
