import type { BehaviorSubject, Observable } from 'rxjs';
import { useVGrid, VGrid } from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import { TableServerNotificationWithFilterDocument } from './notifications.contracts';
import type {
  NotificationRow,
  TableServerNotificationsWithFilterSubscription
} from './notifications.contracts';
import { buildNotificationsColumnDefs } from './notifications.columns';
import type { SortType } from './notifications.widget';
import { clearNotificationsAction } from './grid-actions/clear.notifications.action';
import { inlineNotificationsActions } from './grid-actions/inline.notifications.action';
import { createNotificationsEventHandler } from './event-handlers/notifications.event-handler';
import {
  NotificationHasExecutedQuantity,
  NotificationHazard,
  NotificationNoExecutedQuantity,
  NotificationPending
} from './notifications.utils';
import { TableServerDatasourceService } from '@app/data-access/services/system/table-server/table-server.datasource';
import { NotificationVisibility } from '@app/notifications/notifications.contracts';

export const NOTIFICATIONS_GRID = 'notifications-grid';

// This is the common grid used within the Notifications widget. Each of our 3 grids (my alerts, team alerts, all alerts)
// are variants of this grid.
export const NotificationsGridWidget = ({
  variant$,
  sort$
}: {
  variant$: Observable<NotificationVisibility>;
  sort$: BehaviorSubject<SortType>;
}) => {
  const datasourceService = useService(TableServerDatasourceService);

  const gridProps = useVGrid<NotificationRow>(
    NOTIFICATIONS_GRID,
    (b) =>
      b
        .tableServerColumnLibrary(buildNotificationsColumnDefs())
        .datasource(
          (d) =>
            d
              .source(
                datasourceService.getSource<NotificationRow, TableServerNotificationsWithFilterSubscription>({
                  query: TableServerNotificationWithFilterDocument,
                  getData: (r) => r.tableServerNotificationsWithFilter,
                  includeMarketData: false
                })
              )
              .rowId(({ data }) => data?.id)
              .cacheBlockSize(100) // TODO: Ask about an optimum value to set
        )
        .headerHeight(0) // Make the header height 0 to hide it.
        .rowHeight(60)
        .injectEvents([createNotificationsEventHandler(variant$, sort$)])
        .rowSelection((c) => c.multiple()) // TODO: not yet fully implemented. Need to handle double clicks?
        .rowStateRules({
          pending: (params) => NotificationPending(params.data),
          noExecutedQuantity: (params) => NotificationNoExecutedQuantity(params.data),
          hasExecutedQuantity: (params) => NotificationHasExecutedQuantity(params.data),
          hazard: (params) => NotificationHazard(params.data)
        })
        .actions((a) =>
          a
            .schema((s) => s.action(clearNotificationsAction).action(inlineNotificationsActions))
            .settings((s) => s.fromFile({}))
        ),
    // .sideBar(), // show/hide sidebar during Dev
    // .context not yet implemented
    [variant$, sort$]
  );

  return <VGrid {...gridProps} />;
};

export default NotificationsGridWidget;
