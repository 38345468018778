import { cleanMaybe } from '@oms/shared/util';
import { FORM_EVENT_TYPE, FormBuilder } from '@oms/frontend-foundation';
import type { MontageSettingsSchema } from '@app/common/zod/user-preferences.zod.schemas';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { AppWorkspace } from '@app/app-config/workspace.config';
import { montageSettingsContract } from './montage-settings.form-contract';
import type { MontageSettingsContractType } from './montage-settings.form-contract';
import { formSchemaToFormValues, formValuesToFormSchema } from './montage-settings.util';
import { UserPreferencesDocType } from '@app/data-access/offline/collections/user-preferences.collection';

const getUserPreferences = (workspace: AppWorkspace) =>
  workspace.getMeta().memoryDatabase.collections.user_preferences;

export const montageSettingsBuilder = FormBuilder.create<MontageSettingsSchema, MontageSettingsSchema>(
  'montage-settings-form'
)
  .contract<MontageSettingsContractType>(montageSettingsContract)
  .type('montage-settings')
  .sanitizer((s) =>
    s
      .input(async (input, { container }) => {
        const authService = container.resolve(AuthService);
        const workspace = container.resolve(AppWorkspace);

        const userPreferences = getUserPreferences(workspace);
        const userId = cleanMaybe(authService.getUserId(), '');

        const document = await userPreferences.findOne(userId).exec();

        if (document) {
          // TODO: Remove when dev complete
          // eslint-disable-next-line no-restricted-syntax
          console.debug('!!! Update:', { ...input, ...document.montageSettings });
          return formSchemaToFormValues('update', { ...input, ...document.montageSettings });
        } else {
          // TODO: Remove when dev complete
          // eslint-disable-next-line no-restricted-syntax
          console.debug('!!! Create:', input);
          return formSchemaToFormValues('create', input);
        }
      })
      .output(formValuesToFormSchema)
  )
  .change(async (event, { container, notify }) => {
    const { type } = event;
    switch (type) {
      case FORM_EVENT_TYPE.VALUES_CHANGED: {
        const { formValues } = event.payload;
        // TODO: Remove when dev complete
        // eslint-disable-next-line no-restricted-syntax
        console.log('!!! Form values:', formValues);

        const authService = container.resolve(AuthService);
        const workspace = container.resolve(AppWorkspace);

        const userPreferences = getUserPreferences(workspace);
        const userId = cleanMaybe(authService.getUserId(), '');

        const document = await userPreferences.findOne(userId).exec();
        if (document) {
          // TODO: Remove when dev complete
          // eslint-disable-next-line no-restricted-syntax
          console.debug('!!! ⭐ Current:', document.montageSettings);
        } else {
          // TODO: Remove when dev complete
          // eslint-disable-next-line no-restricted-syntax
          console.debug('!!! ⭕ Current: None');
        }
        break;
      }
      case FORM_EVENT_TYPE.SUBMIT: {
        const { formValues, output: montageSettings } = event.payload;

        const authService = container.resolve(AuthService);
        const workspace = container.resolve(AppWorkspace);

        const userPreferences = getUserPreferences(workspace);
        const userId = cleanMaybe(authService.getUserId(), '');

        const existing = await userPreferences.findOne(userId).exec();

        if (formValues.type === 'create' || !existing) {
          const document: UserPreferencesDocType = {
            id: userId,
            montageSettings
          };
          console.warn('!!! Montage preferences creation:', document);
          await userPreferences.insert(document);
        } else {
          const document: UserPreferencesDocType = {
            id: existing.id || userId,
            montageSettings: { ...existing.montageSettings, ...montageSettings }
          };
          // TODO: Remove when dev complete
          console.warn('!!! Montage preferences update:', document);
          // TODO: Remove when dev complete
          // eslint-disable-next-line no-restricted-syntax
          console.log('!!! Existing:', existing);
          await userPreferences.incrementalUpsert(document);
        }
        break;
      }
      case FORM_EVENT_TYPE.RESET: {
        notify({
          type: 'RESET'
        });
      }
    }
  });

export type MockUserSettingsBuilderType = typeof montageSettingsBuilder;

export default montageSettingsBuilder;
