import type { RxJsonSchema, RxCollection } from 'rxdb-v15';
import type { Prettify } from '@oms/shared/util-types';
import { createPrefixingFn } from '@oms/shared/util';
import type {
  LeftClickSettingsSchema,
  LeftClickSettingsType,
  MontageSettingsSchema
} from '@app/common/zod/user-preferences.zod.schemas';
import { StrategySchema } from '@app/common/zod/trading.zod.schemas';

export type LeftClickSettingsDocType<Type extends LeftClickSettingsType | undefined = undefined> =
  LeftClickSettingsSchema<Type>;

type PartialRxJsonSchema<T> = Required<Pick<RxJsonSchema<T>, 'properties' | 'required'>>;

type LeftClickDefinitions<Type extends LeftClickSettingsType | undefined = undefined> = PartialRxJsonSchema<
  LeftClickSettingsDocType<Type>
>;

export const strategySchema: PartialRxJsonSchema<StrategySchema> = {
  properties: {
    strategyControls: {
      type: 'array',
      items: {
        type: 'object',
        properties: { id: { type: 'string', maxLength: 128 }, value: { type: 'string', maxLength: 128 } }
      }
    },
    strategyName: { type: 'string', maxLength: 128 },
    strategyParams: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          label: { type: 'string', maxLength: 128 },
          name: { type: 'string', maxLength: 128 },
          uiRep: { type: 'string', maxLength: 128 },
          value: { type: 'string', maxLength: 128 }
        }
      }
    },
    venueId: { type: 'string', maxLength: 128 },
    isLayoutsPopulated: { type: 'boolean' },
    orderFormValues: { type: 'object' }
  },
  required: ['strategyName', 'venueId']
} as const;

export const createLeftClickSettingsSchema = <Type extends LeftClickSettingsType | undefined = undefined>(
  type: Type
): LeftClickDefinitions<Type> => {
  const properties: LeftClickDefinitions['properties'] = {
    destinationId: { type: 'string', maxLength: 128 },
    displaySize: { type: 'number', minimum: 0 },
    gtdTimestamp: { type: 'string', maxLength: 128, format: 'date-time' },
    initiateOrder: { type: 'boolean' },
    orderSize: { type: 'string', maxLength: 128 },
    priceType: { type: 'string', maxLength: 128 },
    quantityType: { type: 'string', maxLength: 128 },
    quantityValue: { type: 'number', minimum: 0 },
    sideType: { type: 'string', maxLength: 128 },
    strategy: { type: 'object', ...strategySchema },
    strategyPresets: { type: 'string', maxLength: 128 },
    tifDuration: { type: 'string', maxLength: 128 },
    timeInForceType: { type: 'string', maxLength: 128 },
    timeInForceValue: { type: 'number', minimum: 0 }
  };
  const required: LeftClickDefinitions['required'] = [
    'priceType',
    'quantityType',
    'sideType',
    'timeInForceType'
  ];
  if (type) {
    const prefixingFn = createPrefixingFn(type);
    return {
      properties: prefixingFn(properties),
      required: required.map((property) => prefixingFn(property))
    } as unknown as LeftClickDefinitions<Type>;
  } else {
    return { properties, required } as unknown as LeftClickDefinitions<Type>;
  }
};

export type MontageSettingsDocType = Prettify<MontageSettingsSchema>;

export const createMontageSettingsSchema = (): PartialRxJsonSchema<MontageSettingsDocType> => {
  const askDefs = createLeftClickSettingsSchema('ask');
  const bidDefs = createLeftClickSettingsSchema('bid');
  return {
    properties: {
      ...askDefs.properties,
      ...bidDefs.properties,
      hideOddLots: { type: 'boolean' },
      displayQuotesInShares: { type: 'boolean' },
      sendAttributable: { type: 'boolean' },
      orderMappings: { type: 'object' },
      orderViewOptions: { type: 'object' }
    },
    required: [...askDefs.required, ...bidDefs.required]
  };
};

export type UserPreferencesDocType = {
  id: string;
  montageSettings: MontageSettingsDocType;
};

export const userPreferencesSchema: RxJsonSchema<UserPreferencesDocType> = {
  title: 'User Preferences Schema',
  description: 'Stores user preferences',
  version: 0,
  keyCompression: false,
  primaryKey: 'id',
  type: 'object',
  properties: { id: { type: 'string', maxLength: 128 }, montageSettings: createMontageSettingsSchema() },
  required: ['id']
} as const;

export const USER_PREFERENCES_COLLECTION = {
  schema: userPreferencesSchema
};

export type UserPreferencesCollection = RxCollection<UserPreferencesDocType, {}, {}>;
