import type { IoRepairRequestFragment } from '@oms/generated/frontend';
import type { InvestorOrderEventPayload } from './investor-order-entry.form-common';
import { getParsedPayload } from '../utils/parse-json-utils';

export function getInvestorOrderFromRepair(ioRepair: IoRepairRequestFragment) {
  const { sourceEventPayload } = ioRepair;
  const parsedPayload = getParsedPayload<InvestorOrderEventPayload>(sourceEventPayload);

  if (!parsedPayload) {
    throw new Error('Unable to parse sourceEventPayload');
  }

  // TODO: discuss with the team
  // For IO_MODIFY, the originalCommand contain order details which is the order that failed
  // So to display correct data in the form, we need to use owerride the order with the order from originalCommand
  // which contains the updated modified values
  if (parsedPayload.originalCommand && parsedPayload.originalCommand.order) {
    return {
      ...parsedPayload.order,
      ...parsedPayload.originalCommand.order
    };
  }

  return parsedPayload.order;
}
