import { OrderSideType, PriceType, QuantityType, TimeInForce } from '@oms/generated/frontend';
import type {
  LeftClickSettingsSchema,
  LeftClickSettingsType
} from '@app/common/zod/user-preferences.zod.schemas';

export const DEFAULT_TIME_IN_FORCE = TimeInForce.Duration;

export const LEFT_CLICK_SETTING_DEFAULTS: Record<LeftClickSettingsType, LeftClickSettingsSchema> = {
  ask: {
    initiateOrder: false,
    priceType: PriceType.AskInside,
    quantityType: QuantityType.Blank,
    sideType: OrderSideType.Buy,
    timeInForceType: DEFAULT_TIME_IN_FORCE
  },
  bid: {
    initiateOrder: false,
    priceType: PriceType.BidInside,
    quantityType: QuantityType.Blank,
    sideType: OrderSideType.Sell,
    timeInForceType: DEFAULT_TIME_IN_FORCE
  }
} as const;
