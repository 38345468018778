import type { Maybe } from '@oms/shared/util-types';
import { PrimaryAlertTypeTemp } from '@app/widgets/trading/notifications/notifications.contracts';
import { primaryAlertTypeMapping } from './notification-enums';

export const mapPrimaryAlertType = (
  value?: Maybe<PrimaryAlertTypeTemp> | string,
  defaultVal = value || ''
): string => {
  return value && (primaryAlertTypeMapping[value as never] as string)
    ? primaryAlertTypeMapping[value as never]
    : defaultVal;
};
