import { COMMAND_PALETTE_GROUP } from '@app/commands/command-palette.constants';
import type { CommandPaletteItem } from '@app/common/command-palette/command-palette.contracts';
import { AppDatabase } from '@app/data-access/offline/app-database';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { CommandPaletteService } from '@app/data-access/services/system/command-palette/command-palette.service';
import { SnapshotsService } from '@app/data-access/services/system/snapshots/snapshots.service';
import { openAboutValstro, openConfirmation } from '@app/generated/sdk';
import { exitTauriApp } from '@app/tauri/util';
import { reflow$ } from '@app/workspace/plugin-adaptive-layouts/adaptive-layouts.plugin';
import type { SignalUnsubscribeFn } from '@oms/shared-frontend/rx-broadcast';
import { isTauri } from '@valstro/workspace';
import { inject, singleton } from 'tsyringe';
import type { RegisterCommand } from '../register-command.model';
import { testScoped } from '@app/workspace.registry';
import { AppWorkspace } from '@app/app-config/workspace.config';
import { getLeaderOrTabId } from '@app/common/workspace/workspace.util';

@testScoped
@singleton()
export class SystemCommands implements RegisterCommand {
  private authUnsubscribe: SignalUnsubscribeFn | undefined = undefined;
  constructor(
    @inject(AppWorkspace) private appWorkspace: AppWorkspace,
    @inject(CommandPaletteService) private cmpd: CommandPaletteService,
    @inject(AuthService) private authService: AuthService,
    @inject(AppDatabase) private appDatabase: AppDatabase,
    @inject(SnapshotsService) private snapshotsService: SnapshotsService
  ) {}

  async init() {
    const systemItems: CommandPaletteItem[] = [
      {
        id: 'clear-remembered-windows',
        label: 'Clear window cache',
        category: COMMAND_PALETTE_GROUP.SYSTEM,
        onSelect: async () => {
          await this.snapshotsService.closeWindowsAll();
          const docs = await this.appDatabase.offline.window_contexts.find().exec();
          await this.appDatabase.offline.window_contexts.bulkRemove(docs.map((doc) => doc.type));
        }
      },
      {
        id: 'reset-user-config',
        label: 'Hard reset user config',
        category: COMMAND_PALETTE_GROUP.SYSTEM,
        onSelect: async () => {
          await this.snapshotsService.closeWindowsAll(); // This needs to be before the confirmation dialog to actually work, because it conflicts with the dialog itself
          const [_dialog, api] = await openConfirmation(
            this.appWorkspace,
            getLeaderOrTabId(this.appWorkspace),
            {
              componentProps: {
                message: 'This will reset all user configuration. Are you sure you want to proceed?'
              }
            }
          );

          const event = await api.awaitFirstEvent;

          if (event.type !== 'OK') {
            this.snapshotsService.reloadCurrent().catch(console.error);
            return;
          }

          const [
            windowContexts,
            additionalFields,
            grids,
            gridTemplates,
            actions,
            completedMigrations,
            snapshots
          ] = await Promise.all([
            this.appDatabase.offline.window_contexts.find().exec(),
            this.appDatabase.offline.additional_fields.find().exec(),
            this.appDatabase.offline.grids.find().exec(),
            this.appDatabase.offline.grid_templates.find().exec(),
            this.appDatabase.memory.actions.find().exec(),
            this.appDatabase.memory.completed_migrations.find().exec(),
            this.appDatabase.memory.snapshots.find().exec()
          ]);

          await Promise.all([
            this.appDatabase.offline.window_contexts.bulkRemove(windowContexts.map((doc) => doc.type)),
            this.appDatabase.offline.additional_fields.bulkRemove(
              additionalFields.map((doc) => doc.formType)
            ),
            this.appDatabase.offline.grids.bulkRemove(grids.map((doc) => doc.id)),
            this.appDatabase.offline.grid_templates.bulkRemove(gridTemplates.map((doc) => doc.id)),
            this.appDatabase.memory.actions.bulkRemove(actions.map((doc) => doc.id)),
            this.appDatabase.memory.completed_migrations.bulkRemove(completedMigrations.map((doc) => doc.id)),
            this.appDatabase.memory.snapshots.bulkRemove(snapshots.map((doc) => doc.id))
          ]);
        }
      },
      {
        id: 'about',
        label: 'About Valstro',
        category: COMMAND_PALETTE_GROUP.SYSTEM,
        onSelect: () => {
          void openAboutValstro().catch(console.error);
        }
      },
      {
        id: 'reflow',
        label: 'Reflow windows',
        category: COMMAND_PALETTE_GROUP.SYSTEM,
        onSelect: () => {
          reflow$.next();
        }
      },
      {
        id: 'logout',
        label: 'Logout',
        category: COMMAND_PALETTE_GROUP.SYSTEM,
        onSelect: () => {
          this.authService.logout();
        }
      }
    ];

    if (isTauri()) {
      systemItems.push({
        id: 'logout-exit',
        label: 'Logout & exit',
        category: COMMAND_PALETTE_GROUP.SYSTEM,
        onSelect: exitTauriApp
      });
    }

    await this.cmpd.register(systemItems);
  }

  dispose() {
    if (this.authUnsubscribe) {
      this.authUnsubscribe();
    }
  }
}
