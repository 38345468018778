import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Button, ButtonGroup } from '@oms/shared-frontend/ui-design-system';
import { TradingOrderStatus, ValstroEntitlements } from '@oms/generated/frontend';
import type { VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment } from '@oms/generated/frontend';
import { useOpenCancelTradingOrderDialog } from '@app/common/types/orders/trading-order/open.cancel.trading-order';
import { useOpenTradingOrderModify } from '@app/common/types/orders/trading-order/open.modify.trading-order';
import { useWorkspaceContainer } from '@oms/frontend-foundation';
import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import { useOpenViewInvestorOrder } from '@app/common/types/orders/investor-order/open.view.investor-order';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';

interface ITradingOrderViewUtilityButtonGroupProps {
  orderId: string;
  orderSymbol?: string | null;
  orderStatus?: TradingOrderStatus | null;
}

export const TradingOrderViewUtilityButtonGroup: FC<ITradingOrderViewUtilityButtonGroupProps> = ({
  orderId,
  orderStatus,
  orderSymbol
}) => {
  const container = useWorkspaceContainer();
  const authService = container.resolve(AuthService);
  const hasEntitlement = authService.hasEntitlement([ValstroEntitlements.OrderManage]);
  const isEnabled = hasEntitlement && orderStatus === TradingOrderStatus.Active;
  const investorOrderService = container.resolve(InvestorOrdersService);
  const [linkedInvestorOrderData, setLinkedInvestorOrderData] = useState<IOFragment | null | undefined>(null);

  useEffect(() => {
    investorOrderService
      .getOrders({ filter: { parentTradingOrderId: { equalTo: orderId } } })
      .then((result) => {
        if (result.isSuccess()) {
          setLinkedInvestorOrderData(result.value.data.visibleInvestorOrders?.nodes[0]);
        }
        if (result.isFailure()) {
          console.error(result.errors);
        }
      })
      .catch((e) => console.error(e));
  }, [orderId]);

  const onCancel = useOpenCancelTradingOrderDialog(orderId);
  const onModify = useOpenTradingOrderModify(orderId, orderSymbol);
  const onViewLinkedIO = useOpenViewInvestorOrder(linkedInvestorOrderData);

  return (
    <ButtonGroup spacing={0}>
      <Button onClick={onModify} isDisabled={!isEnabled}>
        Modify
      </Button>
      <Button onClick={onCancel} isDisabled={!isEnabled}>
        Cancel
      </Button>
      {linkedInvestorOrderData && <Button onClick={onViewLinkedIO}>View linked IO</Button>}
    </ButtonGroup>
  );
};
