import type { Maybe, Optional, Prefixed } from '@oms/shared/util-types';
import { OrderSideType, PriceType, QuantityType, TimeInForce } from '@oms/generated/frontend';
import { cleanMaybe, compactMap, createPrefixingFn } from '@oms/shared/util';
import type {
  LeftClickSettingsSchema,
  LeftClickSettingsType,
  MontageSettingsSchema
} from '@app/common/zod/user-preferences.zod.schemas';
import type { StrategySchema } from '@app/common/zod/trading.zod.schemas';
import type { MontageSettingsFormValues } from './montage-settings.form-contract';
import { FIXatdlStrategyValue } from '@app/widgets/trading/route-order/fixatdl-strategy-field/fixatdl-strategy-field.contracts';
import {
  commonTimeInForceOptions,
  makeTifDurationOption,
  makeTifGtdTimestampOption
} from '@app/forms/common/fields/TIF-field/TIF-field.common';
import { LEFT_CLICK_SETTING_DEFAULTS } from './montage-settings.constants';
import { IAdvancedSelectValue, MultiSelectValue } from '@oms/frontend-foundation';
import type { OperationType } from './montage-settings.type';

export const getQuantityLabel = (quantity: QuantityType): string => {
  switch (quantity) {
    case QuantityType.Blank:
      return 'Blank';
    case QuantityType.ExplicitValue:
      return 'Explicit value';
    case QuantityType.OrderSizes:
      return 'Order sizes';
  }
};

export const getPriceTypeLabel = (type: PriceType): string => {
  switch (type) {
    case PriceType.Blank:
      return 'Blank';
    case PriceType.BidInside:
      return 'Bid';
    case PriceType.AskInside:
      return 'Ask';
    case PriceType.Selection:
      return 'Selection';
    case PriceType.Market:
      return 'Market';
  }
};

const PREFIXING_FN = {
  ask: createPrefixingFn<Extract<LeftClickSettingsType, 'ask'>>('ask'),
  bid: createPrefixingFn<Extract<LeftClickSettingsType, 'bid'>>('bid')
};

export const getKey = <Type extends LeftClickSettingsType, Key extends keyof LeftClickSettingsSchema>(
  type: Type,
  key: Key
) => {
  const prefixingFn = PREFIXING_FN[type] as <T>(key: T) => Prefixed<T, Type>;
  return prefixingFn(key);
};

// Advanced selects -------------------------------------------------------- /

type AdvancedSelectKey = keyof Pick<LeftClickSettingsSchema, 'priceType' | 'quantityType' | 'sideType'>;

export const getAdvancedSelectInitialValue = <Key extends AdvancedSelectKey>(
  type: LeftClickSettingsType,
  key: Key,
  label?: string
): IAdvancedSelectValue<LeftClickSettingsSchema[Key]> => {
  const id = LEFT_CLICK_SETTING_DEFAULTS[type][key];
  return {
    id: id as string,
    value: id,
    label
  };
};

function toAdvancedSelect<Value extends string>(
  value: Maybe<Value>,
  options?: { label?: string; copyValue?: false }
): Optional<IAdvancedSelectValue>;
// --------------------- /
function toAdvancedSelect<Value extends string>(
  value: Maybe<Value>,
  options?: {
    label?: string;
    copyValue: true;
  }
): Optional<IAdvancedSelectValue<Value>>;
// Implementation only ------------ /
function toAdvancedSelect<Value extends string>(
  value: Maybe<Value>,
  options?: {
    label?: string;
    copyValue?: boolean;
  }
): Optional<IAdvancedSelectValue> {
  if (typeof value === 'undefined' || value === null) return undefined;
  const base: IAdvancedSelectValue = {
    id: value
  };
  if (typeof options?.label === 'string') base.label = options.label;
  if (options?.copyValue) base.value = value;
  return base;
}

const toMultiSelectValue = <Value extends string, SubValue = unknown>(
  value: Value,
  subValue?: SubValue
): MultiSelectValue<Value, SubValue> => ({
  id: value,
  subValue
});

// Util -------------------------------------------------------- /

const toNumeric = (input: Maybe<string | number>, or?: string | number): Optional<number> => {
  const getFallback = () => {
    if (typeof or === 'undefined') return undefined;
    const numeric = typeof or === 'string' ? Number.parseFloat(or) : or;
    return Number.isNaN(numeric) ? numeric : undefined;
  };
  if (typeof input === 'number') {
    if (Number.isNaN(input)) return getFallback();
    return input;
  }
  if (typeof input !== 'string') return getFallback();
  const numeric = Number.parseFloat(input);
  if (Number.isNaN(numeric)) return getFallback();
  return numeric;
};

export const makeTimeInForceOptions = (type: LeftClickSettingsType) =>
  [makeTifDurationOption(getKey(type, 'tifDuration'))]
    .concat(commonTimeInForceOptions)
    .concat(makeTifGtdTimestampOption(getKey(type, 'gtdTimestamp')));

const convertPriceType = (input: Maybe<string>): Optional<PriceType> =>
  input && Object.values(PriceType).includes(input as PriceType) ? (input as PriceType) : undefined;

const convertQuantityType = (input: Maybe<string>): Optional<QuantityType> =>
  input && Object.values(QuantityType).includes(input as QuantityType) ? (input as QuantityType) : undefined;

// Strategy -------------------------------------------------------- /

export const strategySchemaToFormValue = (
  schema?: Optional<StrategySchema>,
  venueId?: string
): Optional<IAdvancedSelectValue<FIXatdlStrategyValue>> => {
  if (!schema) return undefined;
  return {
    id: schema.venueId,
    value: {
      strategyParams: schema.strategyParams,
      strategyControls: schema.strategyControls,
      strategyName: schema.strategyName,
      venueId: venueId || schema.venueId,
      orderFormValues: schema.orderFormValues as FIXatdlStrategyValue['orderFormValues'],
      isLayoutsPopulated: schema.isLayoutsPopulated
    },
    label: schema.strategyName
  };
};

export const strategyFormValueToSchema = (
  formValue?: IAdvancedSelectValue<FIXatdlStrategyValue>,
  venueId?: string
): Optional<StrategySchema> => {
  if (!formValue || !formValue.value) return undefined;
  const { value: strategy } = formValue;
  return {
    strategyParams: compactMap(strategy.strategyParams),
    strategyControls: compactMap(strategy.strategyControls),
    strategyName: strategy.strategyName,
    venueId: venueId || strategy.venueId,
    orderFormValues: strategy.orderFormValues,
    isLayoutsPopulated: strategy.isLayoutsPopulated
  };
};

// Order -------------------------------------------------------- /

// TODO: Come back to this with order settings...
// const comboBoxItemFromOrderSize = (profile: Maybe<OrderSettingsProfile>) => {
//   const box = comboBoxItemFrom.record<OrderSettingsProfile>(profile, {
//     label: ({ description }) => description,
//     id: ({ id }) => id
//   });

//   return box
//     ? {
//         id: box.id,
//         label: box.label,
//         value: box.label
//       }
//     : undefined;
// };

// TODO: Come back to this with order settings...
// // TODO: Temporary approach, to prevent the bug with validation and saving quantity order size without order size selected from order settings
// const blankIfNoOrderSize =
//   (profile: Maybe<OrderSettingsProfile>) =>
//   (quantityType: QuantityType): QuantityType =>
//     !profile && quantityType === QuantityType.OrderSizes ? QuantityType.Blank : quantityType;

// Form/schema conversion -------------------------------------------------------- /

export const formSchemaToFormValues = (
  type: OperationType,
  schema: Partial<MontageSettingsSchema>
): MontageSettingsFormValues => {
  return {
    type,
    // Ask ----------------------------------------------------------------------- /
    askDestinationId: toAdvancedSelect(schema.askDestinationId),
    askDisplaySize: schema.askDisplaySize,
    askGtdTimestamp: schema.askGtdTimestamp,
    askInitiateOrder: schema.askInitiateOrder || false,
    askOrderSize: toAdvancedSelect(schema.askOrderSize),
    askPriceType: toAdvancedSelect(schema.askPriceType, { copyValue: true }),
    askQuantityType: toAdvancedSelect(schema.askQuantityType, { copyValue: true }),
    askQuantityValue: schema.askQuantityValue,
    askSideType: toAdvancedSelect(schema.askSideType, { copyValue: true }),
    askStrategy: strategySchemaToFormValue(schema.askStrategy, schema.askDestinationId),
    askStrategyPresets: schema.askStrategyPresets,
    askTifDuration: schema.askTifDuration,
    askTimeInForceType: toMultiSelectValue(
      schema.askTimeInForceType || LEFT_CLICK_SETTING_DEFAULTS.ask.timeInForceType,
      schema.askTimeInForceValue?.toString() || ''
    ),
    askTimeInForceValue: schema.askTimeInForceValue?.toString(),
    // Bid ----------------------------------------------------------------------- /
    bidDestinationId: toAdvancedSelect(schema.bidDestinationId),
    bidDisplaySize: schema.bidDisplaySize,
    bidGtdTimestamp: schema.bidGtdTimestamp,
    bidInitiateOrder: schema.bidInitiateOrder || false,
    bidOrderSize: toAdvancedSelect(schema.bidOrderSize),
    bidPriceType: toAdvancedSelect(schema.bidPriceType, { copyValue: true }),
    bidQuantityType: toAdvancedSelect(schema.bidQuantityType, { copyValue: true }),
    bidQuantityValue: schema.bidQuantityValue,
    bidSideType: toAdvancedSelect(schema.bidSideType, { copyValue: true }),
    bidStrategy: strategySchemaToFormValue(schema.bidStrategy, schema.bidDestinationId),
    bidStrategyPresets: schema.bidStrategyPresets,
    bidTimeInForceType: toMultiSelectValue(
      schema.bidTimeInForceType || LEFT_CLICK_SETTING_DEFAULTS.bid.timeInForceType,
      schema.bidTimeInForceValue?.toString() || ''
    ),
    bidTimeInForceValue: schema.bidTimeInForceValue?.toString(),
    // Flags ----------------------------------------------------------------------- /
    displayQuotesInShares: schema.displayQuotesInShares || false,
    hideOddLots: schema.hideOddLots || false,
    sendAttributable: schema.sendAttributable || false
  };
};

export const formValuesToFormSchema = (formValues: MontageSettingsFormValues): MontageSettingsSchema => {
  const {
    // Ask properties
    askDestinationId,
    askDisplaySize,
    askGtdTimestamp,
    askInitiateOrder = false,
    askOrderSize,
    askPriceType,
    askQuantityType,
    askQuantityValue,
    askSideType,
    askStrategy,
    askTifDuration,
    askTimeInForceType,

    // Bid properties
    bidDestinationId,
    bidDisplaySize,
    bidGtdTimestamp,
    bidInitiateOrder = false,
    bidOrderSize,
    bidPriceType,
    bidQuantityType,
    bidQuantityValue,
    bidSideType,
    bidStrategy,
    bidTifDuration,
    bidTimeInForceType,

    // Flags
    displayQuotesInShares = false,
    hideOddLots = false,
    sendAttributable = false
  } = formValues;

  return {
    // Ask properties
    askDestinationId: askDestinationId?.id,
    askDisplaySize: toNumeric(askDisplaySize),
    askGtdTimestamp: askTimeInForceType?.id === TimeInForce.Gtd ? askGtdTimestamp : undefined,
    askInitiateOrder: askInitiateOrder,
    askOrderSize: askOrderSize?.id,
    askPriceType: cleanMaybe(convertPriceType(askPriceType?.id), LEFT_CLICK_SETTING_DEFAULTS.ask.priceType),
    askQuantityType: cleanMaybe(
      convertQuantityType(askQuantityType?.id),
      LEFT_CLICK_SETTING_DEFAULTS.ask.quantityType
    ),
    askQuantityValue: toNumeric(askQuantityValue),
    askSideType: cleanMaybe(
      askSideType?.id as Optional<OrderSideType>,
      LEFT_CLICK_SETTING_DEFAULTS.ask.sideType
    ),
    askStrategy: strategyFormValueToSchema(askStrategy, askDestinationId?.id),
    askTifDuration: askTimeInForceType?.id === TimeInForce.Duration ? askTifDuration : undefined,
    askTimeInForceType: cleanMaybe(askTimeInForceType?.id, LEFT_CLICK_SETTING_DEFAULTS.ask.timeInForceType),
    askTimeInForceValue: toNumeric(askTimeInForceType?.subValue),

    // Bid properties
    bidDestinationId: bidDestinationId?.id,
    bidDisplaySize: toNumeric(bidDisplaySize),
    bidGtdTimestamp: bidTimeInForceType?.id === TimeInForce.Gtd ? bidGtdTimestamp : undefined,
    bidInitiateOrder: bidInitiateOrder,
    bidOrderSize: bidOrderSize?.id,
    bidPriceType: cleanMaybe(convertPriceType(bidPriceType?.id), LEFT_CLICK_SETTING_DEFAULTS.bid.priceType),
    bidQuantityType: cleanMaybe(
      convertQuantityType(bidQuantityType?.id),
      LEFT_CLICK_SETTING_DEFAULTS.bid.quantityType
    ),
    bidQuantityValue: toNumeric(bidQuantityValue),
    bidSideType: cleanMaybe(
      bidSideType?.id as Optional<OrderSideType>,
      LEFT_CLICK_SETTING_DEFAULTS.bid.sideType
    ),
    bidStrategy: strategyFormValueToSchema(bidStrategy, bidDestinationId?.id),
    bidTifDuration: bidTimeInForceType?.id === TimeInForce.Duration ? bidTifDuration : undefined,
    bidTimeInForceType: cleanMaybe(bidTimeInForceType?.id, LEFT_CLICK_SETTING_DEFAULTS.bid.timeInForceType),
    bidTimeInForceValue: toNumeric(bidTimeInForceType?.subValue),

    // Flags
    displayQuotesInShares,
    hideOddLots,
    sendAttributable,

    // Unimplemented
    orderMappings: undefined,
    orderViewOptions: undefined
  };
};
