import { InvestorOrderStatus, ValstroEntitlements } from '@oms/generated/frontend';
import type { VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment } from '@oms/generated/frontend';
import type { ActionDefFactory, ActionComponentConfig, ActionContext } from '@oms/frontend-vgrid';
import { openRouteInvestorOrder } from '../../route-order/route-order.form-open';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';

const isRouteInvestorOrderVisible = (rowData?: IOFragment[]) =>
  rowData !== undefined &&
  rowData.length === 1 &&
  !!rowData[0] &&
  rowData[0]?.status === InvestorOrderStatus.Active &&
  rowData[0]?.openQuantity !== null &&
  rowData[0]?.openQuantity !== undefined &&
  rowData[0]?.openQuantity > 0 &&
  rowData[0]?.__typename === 'VisibleInvestorOrder';

const isActionEnabled = (data: IOFragment[]) => {
  if (data.length !== 1) {
    return false;
  }

  return isRouteInvestorOrderVisible(data);
};

export const openRouteInvestorOrderOnChange = (e: ActionContext<IOFragment>) => {
  const { lifecycle, data } = e;
  e.notify({ isDisabled: !isActionEnabled(data) });

  const selectedRow = data?.[0];
  const id = selectedRow?.id;
  const symbol = selectedRow?.instrument?.mappings?.displayCode || '';

  if (lifecycle === 'change' && id) {
    openRouteInvestorOrder(e.workspace, { id, symbol });
  }
};

export const openRouteInvestorOrderAction: ActionDefFactory<IOFragment> = (builder) =>
  builder
    .name('open_route_investor_order')
    .toolbar((t) =>
      t
        .id('open_route_investor_order_button')
        .component('action-button')
        .location('HorizontalToolbarRight')
        .props({
          content: 'Route Order',
          isDisabled: true
        })
    )
    .access(({ appContainer }) => {
      const authService = appContainer.resolve(AuthService);

      return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
    })
    .customMenu((m) =>
      m
        .name('Route Order')
        .visible(({ rowData }) => isRouteInvestorOrderVisible(rowData))
        .primary()
    )
    .onChange<ActionComponentConfig<IOFragment>>(openRouteInvestorOrderOnChange);
