import { PrimaryAlertTypeTemp } from '@app/widgets/trading/notifications/notifications.contracts';
import type { Notification } from '@app/widgets/trading/notifications/notifications.contracts';

export const NOTIFICATION_VISIBILITY = {
  MINE: 'MINE',
  TEAM: 'TEAM',
  ALL: 'ALL'
} as const;

export type NotificationVisibility = (typeof NOTIFICATION_VISIBILITY)[keyof typeof NOTIFICATION_VISIBILITY];

export const NOTIFICATION_VISIBILITY_VALUE = {
  [NOTIFICATION_VISIBILITY.MINE]: [PrimaryAlertTypeTemp.MyAlert],
  [NOTIFICATION_VISIBILITY.TEAM]: [PrimaryAlertTypeTemp.MyAlert, PrimaryAlertTypeTemp.TeamAlert],
  [NOTIFICATION_VISIBILITY.ALL]: []
} as const;

export type NotificationEvent = {
  notification: Notification;
  userPreferences: Record<string, unknown>;
  visibility: NotificationVisibility;
  error: any;
};

export type NotificationPreferenses = Record<string, unknown>;

export type NotificationSignal = NotificationVisibility;
// export type NotificationSignal = {
//   visibility: NotificationVisibility;
// };
