import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { TradingOrderStatus, ValstroEntitlements } from '@oms/generated/frontend';
import { openCancelTradingOrderDialog } from '@app/common/types/orders/trading-order/open.cancel.trading-order';
import type { TradingOrderRow } from '../trading-order-monitor.contracts';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';

function getActiveRowsLength(data: TradingOrderRow[]) {
  return data.filter((selectedRow) => selectedRow.status === TradingOrderStatus.Active).length;
}

function isVisible(data: TradingOrderRow[]) {
  return getActiveRowsLength(data) > 0;
}

export const cancelTradingOrderOnChange = async (
  ctx: ActionContext<TradingOrderRow, ActionComponentConfig<TradingOrderRow>>
) => {
  const { lifecycle, data, workspace, container, windowActor } = ctx;
  const activeRows = getActiveRowsLength(data);

  ctx.notify({ isDisabled: activeRows === 0 });

  if (lifecycle === 'change') {
    const activeTOIds = data
      .filter((selectedRow) => selectedRow.status === TradingOrderStatus.Active)
      .map((row) => row.id);
    await openCancelTradingOrderDialog({
      workspace,
      container,
      windowId: windowActor.id,
      tradingOrderIds: activeTOIds
    });
  }
};

export const cancelTradingOrderAction: ActionDefFactory<TradingOrderRow> = (builder) =>
  builder
    .name('cancel_trading_order')
    .toolbar((t) =>
      t
        .component('action-button')
        .id('cancel_trading_order_button')
        .location('HorizontalToolbarRight')
        .props({
          isDisabled: true,
          content: 'Cancel'
        })
    )
    .access(({ appContainer }) => {
      const authService = appContainer.resolve(AuthService);

      return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
    })
    .customMenu((m) => m.name('Cancel').visible(({ rowData }) => isVisible(rowData)))
    .onChange<ActionComponentConfig<TradingOrderRow>>(cancelTradingOrderOnChange);
