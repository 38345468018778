import { FormContract, FieldDefinition } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import {
  hiddenFormInfo,
  id,
  instrument,
  investorAccountId,
  quantity,
  limitPrice,
  sideType,
  locate,
  settleCurrency,
  tradeCurrency,
  representativeCode,
  timeInForce,
  settlementType,
  settlementDate,
  settlementContainer,
  customerNotes,
  orderTags,
  receivedTimestamp,
  commission,
  stopPrice,
  primaryBenchmark,
  notionalAmount,
  discretionaryType,
  preAllocationAccount,
  chargeSchedule,
  parentTradingOrderId,
  ownerId,
  settleCounterpartyId,
  stoppedPrice,
  stoppedQuantity,
  stoppedTime
} from './investor-order-entry.form-fields';
import type { InvestorOrderEntryFieldContract } from './investor-order-entry.form-common';
import {
  createAdditionalAllFields,
  createEnrichedField
} from '@app/forms/form-builder/fields/additional-fields/additional-fields.common';
import { createMarketDataField } from '@app/forms/form-builder/fields/market-data-field/market-data-field.common';

export const investorOrderEntryContract = FormContract.create<InvestorOrderEntryFieldContract>()
  .fields({
    hiddenFormInfo,
    id,
    instrument,
    investorAccountId,
    quantity,
    limitPrice,
    sideType,
    locate,
    settleCurrency,
    tradeCurrency,
    representativeCode,
    timeInForce,
    settleType: settlementType,
    settleDate: settlementDate,
    customerNotes,
    orderTags,
    receivedTimestamp,
    rateType: commission.commissionType,
    commission: commission.commission,
    stopPrice,
    primaryBenchmark,
    notionalAmount,
    discretionaryType,
    preAllocationAccount,
    chargeSchedule,
    parentTradingOrderId,
    owner: ownerId,
    settleCounterpartyId,
    stoppedPrice,
    stoppedQuantity,
    stoppedTime
  })
  .schema((f) => {
    const commissionContainer = commission.commissionContainer.build();
    return {
      fields: [
        createMarketDataField('market-data', {
          instrumentFieldName: 'instrument',
          orderQuantityField: 'quantity',
          trackableFieldMap: {
            bid: 'limitPrice',
            ask: 'limitPrice'
          }
        }),
        f.id,
        FieldDefinition.runway('runway', [
          { ...f.investorAccountId, wrapperSx: { flexGrow: 1 } },
          { ...f.instrument, wrapperSx: { flexGrow: 1 } },
          f.sideType,
          f.quantity,
          f.limitPrice
        ]),
        createAdditionalAllFields({
          groups: ['Order', 'Details', 'Settle', 'Stopped fill'],
          fixedFields: [
            'primaryBenchmark',
            settlementContainer.name,
            'settleCurrency',
            'timeInForce',
            commissionContainer.name,
            'locate'
          ],
          fields: [
            // Order
            [createEnrichedField(f.primaryBenchmark, { groupName: 'Order' })],
            [createEnrichedField(settlementContainer.build(), { groupName: 'Order' })],
            [createEnrichedField(f.settleCurrency, { groupName: 'Order' })],
            [createEnrichedField(f.timeInForce, { groupName: 'Order' })],
            [createEnrichedField(commissionContainer, { groupName: 'Order' })],
            [createEnrichedField(f.locate, { groupName: 'Order' })],
            [createEnrichedField(f.notionalAmount, { groupName: 'Order' })],
            [createEnrichedField(f.discretionaryType, { groupName: 'Order' })],
            [createEnrichedField(f.stopPrice, { groupName: 'Order' })],
            [createEnrichedField(f.customerNotes, { groupName: 'Order' })],
            [createEnrichedField(f.orderTags, { groupName: 'Order', colSpan: 3 })],
            // Details
            [createEnrichedField(f.receivedTimestamp, { groupName: 'Details' })],
            [createEnrichedField(f.owner, { groupName: 'Details' })],
            [createEnrichedField(f.representativeCode, { groupName: 'Details' })],
            // Settle
            [createEnrichedField(f.preAllocationAccount, { groupName: 'Settle' })],
            [createEnrichedField(f.settleCounterpartyId, { groupName: 'Settle' })],
            [createEnrichedField(f.tradeCurrency, { groupName: 'Settle' })],
            // Stopped fill,
            [createEnrichedField(f.stoppedQuantity, { groupName: 'Stopped fill' })],
            [createEnrichedField(f.stoppedPrice, { groupName: 'Stopped fill' })],
            [createEnrichedField(f.stoppedTime, { groupName: 'Stopped fill' })]
          ],
          sx: {
            height: 'full'
          }
        }),
        {
          name: 'charge-schedule-section',
          component: 'section',
          fields: [[f.chargeSchedule]],
          sx: { width: 'full', marginTop: 0 },
          style: { minHeight: '3.9rem' }
        }
      ]
    };
  })
  .template('simple', { showSubmitButton: false });

export type InvestorOrderEntryContractType = typeof investorOrderEntryContract;

export type InvestorOrderEntryValues = InferFormValuesFromFormContract<InvestorOrderEntryContractType>;
