import { COLLECTION_MIGRATION_STRATEGY, type VersionedSchemaExport } from './schemas.common';
import type { CollectionName } from '../collections/collections.contracts';

export const APP_SUB_SCHEMA = {
  BUMP_PRICE: 'BUMP_PRICE',
  CREATE_UNBOUND_TO: 'CREATE_UNBOUND_TO',
  SNAPSHOT: 'SNAPSHOT',
  FLEX_LAYOUT: 'FLEX_LAYOUT',
  // Snapshot-based sub-schemas
  ROUTE_ORDER_FORM_VALUES: 'ROUTE_ORDER_FORM_VALUES',
  ROUTE_ORDER_FORM_INPUT: 'ROUTE_ORDER_FORM_INPUT',
  MONTAGE: 'MONTAGE',
  SYSTEM_REMOTE_FORM: 'SYSTEM_REMOTE_FORM'
} as const;

export type AppSubSchemaType = (typeof APP_SUB_SCHEMA)[keyof typeof APP_SUB_SCHEMA];

export const SUB_SCHEMA_TYPE = {
  SNAPSHOT_COMPONENT: 'snapshot-component',
  SNAPSHOT_FORM_INPUT: 'snapshot-form-input',
  SNAPSHOT_FORM_VALUES: 'snapshot-form-values',
  ACTION: 'action',
  OTHER: 'other'
} as const;

export type SubSchemaType = (typeof SUB_SCHEMA_TYPE)[keyof typeof SUB_SCHEMA_TYPE];

export type SubSchemaItem = {
  collection?: CollectionName;
  dependencies?: AppSubSchemaType[];
  subSchemaFolderPath: string;
  versionedSchemas: VersionedSchemaExport<any>[];
  type: SubSchemaType;
};

export type AppSubSchemaRegistery = Record<AppSubSchemaType, SubSchemaItem>;
