import { InvestorOrderStatus, TradingOrderStatus } from '@oms/generated/frontend';
import type {
  InvestorOrder,
  VisibleInvestorOrderInfoWithAllocationsFragment,
  VisibleTradingOrderInfoFragment
} from '@oms/generated/frontend';
import type { TradingOrderRow } from '../trading-order-monitor-table-server/trading-order-monitor.contracts';
import type { Maybe } from '@oms/shared/util-types';

// Determines if the given IO row should have the "Pending" row state.
export function IOPending(
  rowData: Maybe<VisibleInvestorOrderInfoWithAllocationsFragment | InvestorOrder>
): boolean {
  return (
    rowData?.status === InvestorOrderStatus.CancellationPending ||
    rowData?.status === InvestorOrderStatus.ModificationPending ||
    rowData?.status === InvestorOrderStatus.Unaccepted ||
    rowData?.status === InvestorOrderStatus.ExpiryPending
  );
}

// Determines if the given IO row should have the "No Executed Quantity" row state.
export function IONoExecutedQuantity(
  rowData: Maybe<VisibleInvestorOrderInfoWithAllocationsFragment | InvestorOrder>
): boolean {
  return (
    rowData?.executedQuantity === 0 &&
    (rowData?.status === InvestorOrderStatus.Cancelled ||
      rowData?.status === InvestorOrderStatus.Expired ||
      rowData?.status === InvestorOrderStatus.Inactive ||
      rowData?.status === InvestorOrderStatus.ExecutionCancelled)
  );
}

// Determines if the given IO row should have the "Has Executed Quantity" row state.
export function IOHasExecutedQuantity(
  rowData: Maybe<VisibleInvestorOrderInfoWithAllocationsFragment | InvestorOrder>
): boolean {
  return (
    typeof rowData?.executedQuantity === 'number' &&
    rowData?.executedQuantity > 0 &&
    (rowData?.status === InvestorOrderStatus.Cancelled ||
      rowData?.status === InvestorOrderStatus.Expired ||
      rowData?.status === InvestorOrderStatus.Inactive ||
      rowData?.status === InvestorOrderStatus.FullyFilled ||
      rowData?.status === InvestorOrderStatus.ExecutionCancelled)
  );
}

// Determines if the given TO row should have the "Pending" row state.
export function TOPending(rowData: VisibleTradingOrderInfoFragment | TradingOrderRow | undefined): boolean {
  return (
    rowData?.status === TradingOrderStatus.PendingCancel ||
    rowData?.status === TradingOrderStatus.ModifyRequested ||
    rowData?.status === TradingOrderStatus.ModifyPending ||
    rowData?.status === TradingOrderStatus.ExpiryRequested ||
    rowData?.status === TradingOrderStatus.ExpiryPending ||
    (rowData?.status === TradingOrderStatus.RouteRequested && rowData?.executedQuantity === 0)
  );
}

// Determines if the given TO row should have the "No Executed Quantity" row state.
export function TONoExecutedQuantity(
  rowData: VisibleTradingOrderInfoFragment | TradingOrderRow | undefined
): boolean {
  return (
    rowData?.status === TradingOrderStatus.Rejected ||
    (rowData?.executedQuantity === 0 &&
      (rowData?.status === TradingOrderStatus.Inactive ||
        rowData?.status === TradingOrderStatus.Expired ||
        rowData?.status === TradingOrderStatus.Cancelled ||
        rowData?.status === TradingOrderStatus.TradeCancelled))
  );
}

// Determines if the given TO row should have the "Has Executed Quantity" row state.
export function TOHasExecutedQuantity(
  rowData: VisibleTradingOrderInfoFragment | TradingOrderRow | undefined
): boolean {
  return (
    typeof rowData?.executedQuantity === 'number' &&
    rowData?.executedQuantity > 0 &&
    (rowData?.status === TradingOrderStatus.Inactive ||
      rowData?.status === TradingOrderStatus.Expired ||
      rowData?.status === TradingOrderStatus.FullyFilled ||
      rowData?.status === TradingOrderStatus.Cancelled ||
      rowData?.status === TradingOrderStatus.TradeCancelled)
  );
}
